@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    src: local('Segoe UI'),
        url(/static/media/Seg.fa52c78c.woff2) format('woff2'),
        url(/static/media/Seg.7afe6050.ttf) format('truetype');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    overflow-y: auto;
}

#root {
    height: "100% !important";
}

html {
    box-sizing: border-box;
    overflow: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

/* latin */
@font-face {
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    src: local("Anton Regular"), local("Anton-Regular"),
        url(https://fonts.gstatic.com/s/anton/v10/1Ptgg87LROyAm3Kz-C8.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
            format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
            format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
            format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

.simplebar-mask {
    width: 95% !important;
}



.Tutorial_link__Ek8QU {
    cursor: pointer;
    text-decoration: none; /* no underline */
    color: black;
    width: 100%;
    height: 100%;
    background-color: #efefef;
}

.Tutorial_tutorialContainer__3ZX27:hover {
    cursor: pointer;
    background-color: #efefef;
}
.AudioTheory_container__1Ii1e {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.AudioTheory_item__1h_P6 {
    margin-top: 15px;
}

.AudioTheory_audioWaveCanvas__2ysNB {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px solid gray;
}

.AudioTheory_grid__DLqw5  div {
    border-top: 1px solid black;
    border-right: 1px solid black;
    padding: 5px;
}

.AudioTheory_grid__DLqw5 {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". ." ". ." ". .";
      
}


.ProjectList_root__AHUt4 {
    width: "100%";
    max-width: 360;
    color: white;
} 

.ProjectList_listItemLight__2Ljrm {
    border-bottom: 1px solid rgba(255,255,255,0.72);
    color: white;
}

.ProjectList_listItemLight__2Ljrm:hover {
    background-color: #efefef;
}

.ProjectList_listItemLight__2Ljrm p {
    color:rgba(255,255,255,0.62);
}


.ProjectList_listItemDark__17PQU {
    border-bottom: 1px solid rgba(0,0,0,0.72);
    color: white;
}

.ProjectList_listItemDark__17PQU:hover {
    background-color: #efefef;
}

.ProjectList_listItemDark__17PQU p {
    color:rgba(0,0,0,0.62);
}
